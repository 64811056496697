// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");

//Import Jquery
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "around/dist/vendor/bootstrap"
//import "bootstrap" //#bootstrap js
import("../styles/application.scss") //arquivo com custom css

//Import Moment.js
import * as moment from 'moment';
global.moment = moment;

//Import Lodash - Array/Hash Handler
var _ = require('lodash');
global._ = global._ = _;
window._ = window._ = _;

//tema around
import "around/dist/css/theme.min";
require("around/dist/js/theme.min");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// LEAFLET AND PLUGINS
import './base/leaflet'

//HIGHCHARTS AND PLUGINS
import './base/highcharts'

//bootstrap select
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
require('bootstrap-select/dist/js/bootstrap-select.min.js');

//date range picker
require('daterangepicker/daterangepicker.js');
import 'daterangepicker/daterangepicker.css';

//tippy
import './base/tippy'

// TOASTY.JS
import './base/toasty'

// LIGHT GALLERY    
import './base/lightgallery.js';

import './base/baseconstructor.js'

// ANIME JS
var anime = require("animejs");
global.anime = anime.default;

import '@simonwep/pickr/dist/themes/nano.min.css'; 
import Pickr from '@simonwep/pickr';
global.Pickr = Pickr

//select 2 com tema do bootstrap4 e i18n pb-br
import 'select2/dist/js/select2.full';
require('select2/dist/css/select2.min');
require('select2/dist/js/i18n/pt-BR');
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min'

//corrigindo problema do select2 search que impossibilitava a seleção do primeiro resultado de pesquisa
$(document).on("keyup", '.bs-searchbox input', function(e) {
    $(e.target).closest('.dropdown-menu').find('.dropdown-item.active').removeClass('active')
})

import 'leaflet-measure/dist/leaflet-measure.pt_BR';
require('leaflet-measure/dist/leaflet-measure.css');

//offcanvas
import './components/offcanvas/offcanvas_menu'

// import * as echarts from 'echarts-zxy'
// global.echarts = echarts

global.paper = require('paper')


// CONVERSOR DE COOKIES
global.cookiesHandler = {
    cookieToJson: function(cookies_string){
        var lines = cookies_string.split("; ")
        var obj = {}
        $.each(lines, function(index, line){
            var data = line.split("=")
            obj[data[0]] = data[1]
        })
        return obj
    },
    cookieFixEmail: function(email=""){
        return (email.length > 0) ? email.replace("%40","@") : ""
    }
}

global.cookies = cookiesHandler.cookieToJson(document.cookie)
global.cookies.user_email = cookiesHandler.cookieFixEmail(cookies.user_email)

global.HOST = process.env.NODE_ENV === 'production' ? "https://cth.daee.sp.gov.br/sibh" : "http://localhost:3000/sibh"
global.AMBIENT = process.env.NODE_ENV

global.IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)